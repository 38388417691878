







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FeaturePoint extends Vue {
  @Prop({ required: true })
  public imagePath!: string;

  @Prop({ required: true })
  public title!: string;
}
