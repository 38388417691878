









import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HeroV2 extends Vue {
  public heroItem: any = {
    imageSrc: '/assets/images/photos/service-guarantee.webp',
    body: 'CALL TO REQUEST AN IN PERSON <br /> CONSULTATION WITH OUR FRIENDLY TEAM <br /> PH: 07 4051 9300',
    action: { label: 'CONTACT FORM', href: '/contact-us' },
  };
}
